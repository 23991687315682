import React, { useEffect, useState } from "react";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomButton from "../../../shared/KatakomButton";
import styled from "styled-components";
import { KatastarCard } from "../style";
import KatakomSelect, { SelectOption } from "../../../shared/KatakomSelect";
import {
  katastarskeOpstineOptions,
  opstineOptions,
} from "../../../../register/register";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import {
  CardContainer,
  CardHeader,
  CardTitle,
  CardWrapper,
  Column,
  LayoutWraper,
} from "../../style";
import api from "../../../../services/api";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import { RowWrapper } from "../pretragaPoImenu/PretragaPoImenuLayout";
import { setUser } from "../../../../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { RenderKatastarData } from "../renderKatastarData/RenderKatastarData";
import KatakomAddressSelect from "../../../shared/KatakomAdressSelect";

const PretragaPoAdresiLayout: React.FC = () => {
  const [koid, setKoid] = React.useState<string>("");
  const [matBrOpstine, setMatBrOpstine] = React.useState<string>("");
  const [opstinaID, setOpstinaID] = React.useState<string>("");
  const [ulicaID, setUlicaID] = React.useState<string>("");
  const [kucnibroj, setKucnibroj] = React.useState<string>("");
  const [koNaziv, setKoNaziv] = React.useState<string>("");
  const [brojParcele, setBrojParcele] = React.useState<string>("");
  const [podBrojParcele, setPodBrojParcele] = React.useState<string>("");
  const [podBrParc, setPodBrParc] = React.useState<string>("");
  const [brojObjekta, setBrojObjekta] = React.useState<string>("");
  const [data, setData] = React.useState<any>(null);
  const [detrimendHeight, setDetrimendHeight] = React.useState<string>(
    "calc(100vh - 120px)"
  );
  const [nelegalni, setNelegalni] = React.useState({});
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = useMobileCheck();
  const user = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const { sidebar } = useSidebar();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setDetrimendHeight("20vh");
    } else {
      setDetrimendHeight("calc(100vh - 120px)");
    }
  }, [data]);

  useEffect(() => {
    if (koid && brojParcele) {
      fatchData();
    }
  }, [koid, brojParcele]);

  const fatchData = async () => {
    setActiveTab(0);
    let queryParams: any = {};
    let nelegalniQueryParams: any = {};

    queryParams.shouldCount = true;

    if (koid !== "") {
      queryParams.koid = koid;
      nelegalniQueryParams.koId = koid;
    }

    if (matBrOpstine !== "") {
      nelegalniQueryParams.matBrOpstine = matBrOpstine;
    }

    let podbrojParceleValue = "0";

    if (podBrojParcele) {
      podbrojParceleValue = podBrojParcele;
    }

    queryParams.brojParcele = brojParcele;
    queryParams.podbrojParcele = podbrojParceleValue;

    nelegalniQueryParams.brojParcele = brojParcele;
    if (podbrojParceleValue !== "0") {
      nelegalniQueryParams.brojParcele += "/" + podbrojParceleValue;
    }

    const res = await api.get(
      `api/v1/pretraga-parcele-po-broju-parcele`,
      queryParams
    );
    const nelegalniRes = await api.get(
      `api/v1/pretraga-nelegalnih-objekata`,
      nelegalniQueryParams
    );

    setNelegalni({
      total: nelegalniRes?.data?.meta?.total,
      objectIds: nelegalniRes?.data?.data?.map((item: any) => item.objekatId),
    });

    if (res?.data.length === 0) {
      setData(null);
      return;
    }
    setData(res?.data);

    const u = {
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      email: user.email,
      theme: user.theme,
      brojPretraga: user.brojPretraga + 1,
    };

    dispatch(setUser(u));
  };

  const fetchAdresaParcela = async () => {
    let queryParams: any = {};
    queryParams.kucnibroj = 187;

    if (ulicaID !== "") {
      queryParams.ulicaID = ulicaID;
    }

    if (kucnibroj !== "") {
      queryParams.kucnibroj = kucnibroj;
    }

    const res = await api.get(
      `api/v1/pretraga-objekata-po-adresi`,
      queryParams
    );

    setKoid(res.data[0].koID);
    setBrojParcele(res.data[0]?.brParc);
    setPodBrojParcele(res.data[0]?.podBrParcele);
    setPodBrParc(res.data[0]?.podBrParc);
  };

  const redirectToRGZ = () => {
    window.open(
      `https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${koid}&parcelNumber=${brojParcele}`,
      "_blank"
    );
  };

  const redirectToDRR = () => {
    window.open(
      `https://drr.geosrbija.rs/drr/map?cadmun=${koid}&parcelNumber=${brojParcele}`,
      "_blank"
    );
  };

  const redirectToNelegalni = () => {
    window.open(
      //@ts-ignore
      `https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${koid}&parcelNumber=${brojParcele}&objectId=${nelegalni.objectIds[0]}`,
      "_blank"
    );
  };

  return (
    <LayoutWraper sidebarOpen={sidebar}>
      <CardWrapper>
        <KatastarCard height={detrimendHeight}>
          <CardContainer>
            <CardHeader>
              <CardTitle>ПРЕТРАГА ПО АДРЕСИ</CardTitle>
            </CardHeader>
            {isMobile ? (
              <Column
                style={{
                  marginTop: "0px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <KatakomSelect
                  options={opstineOptions}
                  value={opstinaID}
                  name="opstinaID"
                  size="m"
                  label="Општина*"
                  error={false}
                  onChange={(option: SelectOption) => {
                    const oNaziv = option.value.split("_")[0];
                    const oID = option.value.split("_")[1];
                    setKoNaziv(oNaziv);
                    setOpstinaID(oID);
                  }}
                  style={{ margin: "20px 0px 0px 0px" }}
                />
                <KatakomAddressSelect
                  opstinaID={opstinaID}
                  value={ulicaID}
                  onChange={(selected: { label: string; value: string }) => {
                    setUlicaID(selected.value);
                  }}
                  style={{ margin: "20px 0 0 0" }}
                />
                <KatakomInput
                  type={"number"}
                  value={kucnibroj}
                  name={"kucnibroj"}
                  error={false}
                  style={{ margin: "20px 0 0 0" }}
                  onChange={(e) => {
                    setKucnibroj(e.target.value);
                  }}
                />
                <KatakomButton
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                  size={"xl"}
                  label={"ПРЕТРАЖИ"}
                  onClick={fetchAdresaParcela}
                />
              </Column>
            ) : (
              <RowWrapper style={{ marginTop: "20px" }}>
                <KatakomSelect
                  options={opstineOptions}
                  value={opstinaID}
                  name="opstinaID"
                  size="m"
                  label="Општина*"
                  error={false}
                  onChange={(option: SelectOption) => {
                    const oNaziv = option.value.split("_")[0];
                    const oID = option.value.split("_")[1];
                    setKoNaziv(oNaziv);
                    setOpstinaID(oID);
                  }}
                />
                <KatakomAddressSelect
                  opstinaID={opstinaID}
                  value={ulicaID}
                  onChange={(selected: { label: string; value: string }) => {
                    setUlicaID(selected.value);
                  }}
                  style={{ marginTop: "40px" }}
                />
                <KatakomInput
                  style={{ marginTop: "0px" }}
                  type={"text"}
                  value={kucnibroj}
                  name={"kucnibroj"}
                  label="Кућни број"
                  error={false}
                  onChange={(e) => {
                    setKucnibroj(e.target.value);
                  }}
                />
                <KatakomButton
                  size={screenWidth < 1450 ? "s" : "m"}
                  label={"ПРЕТРАЖИ"}
                  onClick={fetchAdresaParcela}
                  style={{ marginTop: "40px" }}
                />
              </RowWrapper>
            )}

            {data !== null && !data ? (
              <DataNotFound>*Нема података за унету вредност</DataNotFound>
            ) : null}
          </CardContainer>
        </KatastarCard>
      </CardWrapper>

      {data ? (
        <RenderKatastarData
          data={data}
          nelegalni={nelegalni}
          redirectToRGZ={redirectToRGZ}
          redirectToDRR={redirectToDRR}
          redirectToNelegalni={redirectToNelegalni}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          koid={koid}
          brOpstine={matBrOpstine}
          brojParcele={brojParcele}
          podbrojParcele={podBrojParcele || "0"}
        />
      ) : null}
    </LayoutWraper>
  );
};

export const DataNotFound = styled.div`
  display: flex;
  margin: 20px 50px 0px 30px;
  font-size: 15px;
  font-weight: 600;
`;

export default PretragaPoAdresiLayout;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RiCloseCircleLine } from "react-icons/ri";
import { themeColors } from "../../constants/constants";
import api from "../../services/api";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input`
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  color: ${themeColors.selectButtonColor};
  background: ${themeColors.selectButtonBackgrounColor};
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 4px;
  box-shadow: 5px 5px 10px 0px #00000040;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
  overflow: hidden;
  position: relative;
  width: 270px;
  padding-left: 10px;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  background-color: ${themeColors.dropdownBackgroundColor};
  border: 1px solid ${themeColors.dropdownBorderColor};
  border-radius: 4px;
  overflow-y: auto;
  z-index: 1000;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Option = styled.li<{ highlighted: boolean }>`
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.highlighted
      ? themeColors.highlightedOptionBackground
      : "transparent"};
  color: ${themeColors.katakomSelectLabelColor};

  &:hover {
    background-color: ${themeColors.highlightedOptionBackground};
  }
`;

const ClearIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${themeColors.clearIconColor};
`;

interface KatakomAddressSelectProps {
  opstinaID: string;
  value: string;
  onChange: (selected: { label: string; value: string }) => void;
  style?: React.CSSProperties;
}

const KatakomAddressSelect: React.FC<KatakomAddressSelectProps> = ({
  opstinaID,
  value,
  onChange,
  style,
}) => {
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  // Clear search and options when opstinaID changes
  useEffect(() => {
    setSearch("");
    setOptions([]);
  }, [opstinaID]);

  const fetchOptions = async (query: string) => {
    if (!opstinaID) return; // Prevent fetch without valid opstinaID
    setLoading(true);
    try {
      const response = await api.get(
        `/api/v1/pretraga-ulica?opstinaID=${opstinaID}&ulicaNaziv=${query}`
      );
      const fetchedOptions = response.data.map(
        (ulica: { ulicaID: number; ulicaNazivCir: string }) => ({
          label: ulica.ulicaNazivCir,
          value: ulica.ulicaID,
        })
      );
      setOptions(fetchedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearch(query);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = window.setTimeout(() => {
      if (query.length >= 3) {
        fetchOptions(query);
      } else {
        setOptions([]);
      }
    }, 500);
  };

  const handleOptionSelect = (option: { label: string; value: string }) => {
    setSearch(option.label);
    setOptions([]);
    onChange(option);
  };

  const handleClear = () => {
    setSearch("");
    setOptions([]);
    onChange({ label: "", value: "" });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) =>
        prev < options.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      handleOptionSelect(options[highlightedIndex]);
    }
  };

  return (
    <SelectWrapper style={{ ...style }}>
      <SelectContainer>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder="Претражите улицу..."
        />
        {search && (
          <ClearIcon onClick={handleClear}>
            <RiCloseCircleLine />
          </ClearIcon>
        )}
        {options.length > 0 && (
          <Dropdown>
            {options.map((option, index) => (
              <Option
                key={option.value}
                highlighted={index === highlightedIndex}
                onMouseDown={() => handleOptionSelect(option)}
              >
                {option.label}
              </Option>
            ))}
          </Dropdown>
        )}
      </SelectContainer>
    </SelectWrapper>
  );
};

export default KatakomAddressSelect;

import { ROLE } from "../../../enums/role";

export interface SidebarItem {
    title: string;
    path: string;
    icon: string;
    permissions: ROLE[];
    subNav?: SidebarSubItem[];
}

interface SidebarSubItem {
    title: string;
    path: string;
    icon: string;
}

export const SidebarData: SidebarItem[] = [
    {
        title: "Захтеви",
        path: "/noviPredmet",
        icon: "zahtevi",
        permissions: [],
        subNav: [
            {
                title: "Нови предмет/захтев",
                path: "/noviPredmet",
                icon: "",
                // permissions: [ROLE.ROLE_IZVRSITELJ],
            },
            {
                title: "Нови предмет/конверзија",
                path: "/noviPredmetKonverzija",
                icon: "",
                // permissions: [ROLE.ROLE_ADVOKAT],
            },
            {
                title: "Направи нови предмет",
                path: "/noviPredmetAdvokati",
                icon: "",
                // permissions: [ROLE.ROLE_ADVOKAT],
            },
        ],
    },
    {
        title: "Статус поднетог захтева",
        path: "/statusZahteva",
        icon: "status",
        permissions: [],
    },
    {
        title: "E-Статус предмета",
        path: "/eStatus",
        icon: "status",
        permissions: [],
    },
    {
        title: "Претрага",
        path: "/pretraga",
        icon: "pretraga",
        permissions: [],
        subNav: [
            {
                title: "Претрага лица по имену",
                path: "/pretraga/pretragaPoImenu",
                icon: "",
            },
            {
                title: "Претрага лица по матичном броју",
                path: "/pretraga/pretragaPoMaticnom",
                icon: "",
            },
            {
                title: "Претрага по броју парцеле",
                path: "/pretraga/pretragaPoParceli",
                icon: "",
            },
            {
                title: "Претрага по броју листа непокретности",
                path: "/pretraga/pretragaPoListuNepokretnosti",
                icon: "",
            },
            {
                title: "Претрага по адреси",
                path: "/pretraga/pretragaPoAdresi",
                icon: "",
            },
            {
                title: "Претрага нелегалних објеката",
                path: "/pretragaNelegalnihObjekata",
                icon: "",
            },
        ],
    },
    {
        title: "Гео Србија",
        path: "/geoSrbija",
        icon: "geoSrbija",
        permissions: [],
    },
];
